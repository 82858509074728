import config from '@public-config'
import getConfig from 'next/config'
import Router from 'next/router'
import qs from 'qs'
import { ArticleContentType, ArticleSearchType } from '@apollo-types/globalTypes'

const { publicRuntimeConfig } = getConfig()

export const goBackTo = (url = '') => {
  if (!window) return
  if (window.self !== window.top && !!window.parent.closePopFrame) {
    window.parent.closePopFrame()
  } else if (window.RNApp && window.RNApp.goBack) {
    window.RNApp.goBack()
  } else {
    window.close()
    if (window.history.back) {
      window.history.back()
    } else if (url) {
      window.setTimeout(() => (window.location.href = url), 100)
    }
  }
}

export const urlParam = (name) => {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href)
  return results == null ? null : decodeURI(results[1]) || 0
}

export const openPoster = ({ mid = '', type = '', target = '_self' }) => {
  if (!mid) return
  const subPath = ['follow', 'fans', 'view'].includes(type) ? `/${type}` : ''
  openPost(`${config.myhost}/user/${mid}${subPath}`, target)
}

export const openPlace = ({ loc = '', target = '_blank' }) => {
  if (!loc) return
  openPost(`${config.myhost}/${loc.replace(/\./g, '/')}`, target)
}

export const openPost = (url, target = '_blank', notifyRNApp = true) => {
  if (!url) return
  const newUrl = detectHttp(url) ? url : `${config.appUrl}${url}`

  if (url.indexOf(`${config.myhost}/article/`) === 0) return openBrowser(url, target)

  if (notifyRNApp && window.RNApp && window.RNApp.webview2) return window.RNApp.webview2(newUrl)

  if (urlParam('popadmin')) return (location.href = url)

  if (target === '_route') return Router.push(url)

  window.open(url, target)
}

export const openBrowser = (url, target = '_blank') => {
  if (window.RNApp && window.RNApp.openBrowser) {
    window.RNApp.openBrowser(url)
    return
  }
  window.open(url, target)
}

export const convertToUrl = (pathOrUrl) => {
  return pathOrUrl.trim().startsWith('http') ? pathOrUrl : `${config.appUrl}${pathOrUrl}`
}

export const referralAndOpen = (pathOrUrl, referralPathOrUrl) => {
  if (!pathOrUrl || !referralPathOrUrl) return
  const url = convertToUrl(pathOrUrl)
  const referralUrl = convertToUrl(referralPathOrUrl)

  if (window.RNApp && window.RNApp.postMessage) {
    window.RNApp.navigatePage(referralUrl)
    window.RNApp.pushPage(url)
    return
  }
  window.location.href = referralUrl
  window.open(url, '_blank')
}

export const openPlaceInRNMap = (place) => {
  const placeId = place.get('postID')
  const placeData = place.toJS()
  const coordinates = place.getIn(['place', 'coordinates']).toJS()
  openApp('goFoodMapPage', {
    action: 'goToPlace',
    placeId,
    place: placeData,
    coordinates,
  })
}

export const openApp = (type, data) => {
  if (window.RNApp) {
    window.RNApp.postMessage(type, data)
  } else {
    window.open(config.downloadAppUrl)
  }
}

export const detectHttp = (url) => url.trim().startsWith('http') || url.trim().startsWith('https')

const toAbsoluteUrl =
  (baseUrl) =>
  (path = '') => {
    if (!path) return baseUrl
    return detectHttp(path)
      ? path.trim()
      : `${baseUrl.replace(/\/$/gi, '')}/${path.trim().replace(/^\//gi, '')}`
  }

export const imageAssetUrl = toAbsoluteUrl(config.imageAssetUrl)
export const imageUrl = toAbsoluteUrl(config.imageBaseUrl)
export const staticImageUrl = toAbsoluteUrl(config.appStaticUrl)
export const publicUrl = toAbsoluteUrl(config.myhost)
export const apiUrl = toAbsoluteUrl(config.coreApiEndpoint)

export const rssUrl = (path = '') => apiUrl(`${path}/rss`)

export const ogUrl = toAbsoluteUrl(config.myhost)

export const appLinks = (path = '') =>
  detectHttp(path)
    ? `popdaily://${path.trim().replace(/^https:\/\/[a-zA-Z-]+\.popdaily\.com\.tw\//, '')}`
    : `popdaily://${path.trim().replace(/^\//gi, '')}`

export const appName = 'PopDaily 波波黛莉的異想世界'

export const postUrl = (postID = '') => toAbsoluteUrl(config.myhost)(postID.replace(/\./g, '/'))

type ShaperOptions = {
  'resize-w'?: number
  'resize-h'?: number
  quality?: number
}

export const shaperUrl = (url?: string) => {
  // TODO: 此為治標解法，之後不在此做 null check，預期 url 為字串來處理即可
  if (!url) return () => ''

  const src = toAbsoluteUrl(config.shaperUrl)(
    url.replace(/^https:\/\/[a-zA-Z-]+\.popdaily\.com\.tw\/([^?#]+).*/, '$1'),
  )

  return (options?: ShaperOptions) => {
    if (!options) return src

    const query = Object.entries(options)
      .map((entry) => entry.map(encodeURIComponent).join('='))
      .join('&')

    return src + '?' + query
  }
}

export const searchUrl = (keyword?: string, page?: number, filter?: string, sort?: string) => {
  const normalizedKeyword = keyword.replace(/^＃/, '#').trim()

  if (!normalizedKeyword) return publicUrl('/search')

  // 如果沒有填 page 或是 page 為 1 時
  // 皆導向 /search/:keyword/
  const pageParams = page && page !== 1 ? `/${page}` : ''
  const keywordParams = `/${encodeURIComponent(normalizedKeyword)}`
  const queryString = qs.stringify({
    filter: filter ?? ArticleContentType.ALL,
    sort: sort ?? ArticleSearchType.RELEVANT,
  })

  return publicUrl(`/search${keywordParams}${pageParams}?${queryString}`)
}

export const tagUrl = (tag?: string, page?: number) => {
  const pageParams = page && page !== 1 ? `/${page}` : ''
  const normalizedTag = tag.replace(/^＃/, '#').trim()
  const tagParams = tag ? `/${encodeURIComponent(normalizedTag)}` : ''

  return publicUrl(`/tag${tagParams}${pageParams}`)
}

export const popSelectUrl = toAbsoluteUrl(publicRuntimeConfig.popSelectUrl)

export const feedbackUrl = ({ url, userId }) => {
  return `https://docs.google.com/forms/d/e/1FAIpQLSdc76PUHg55pUaR7eX44XG2EuuhsViSfAJg7NwuCUq-i2poqQ/viewform?entry.2008071894=${url}&entry.1582737372=${userId}`
}

export default {
  ...config,
  // env
  get envType() {
    return config.env
  }, // equal process.env.NODE_ENV
  // get buildMode() { return config.buildMode },   // equal process.env.BUILD_MODE
  // base url
  get hostUrl() {
    return config.myhost
  }, // equal process.env.MY_HOST,
  get appUrl() {
    return config.appUrl
  }, // equal process.env.APP_URL,
  // api url
  get apiUrl() {
    return config.coreApiEndpoint
  }, // equal process.env.CORE_API_ENDPOINT
  // images Url
  get staticUrl() {
    return config.appStaticUrl
  }, // equal process.env.STATIC_URL,
  get imgUrl() {
    return config.imageBaseUrl
  }, // equal process.env.IMAGE_BASE_URL,
  // prefix cookie domain
  get prefixPath() {
    return config.prefixPath
  }, // equal process.env.PREFIX_PATH,
  get cookieDomain() {
    return config.cookieDomain
  }, // equal process.env.COOKIE_DOMAIN,
  // etc
  get appUrlFE() {
    return config.appUrlFE
  }, // equal process.env.MY_HOST
  get fbShareDefaultUrl() {
    return config.fbShareDefaultUrl
  },
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArticleContentType {
  ALL = 'ALL',
  PGC = 'PGC',
  PUGC = 'PUGC',
  UGC = 'UGC',
}

export enum ArticleSearchType {
  HOT = 'HOT',
  LATEST = 'LATEST',
  RELEVANT = 'RELEVANT',
}

export enum CategoryEnableType {
  ALL = 'ALL',
  ENABLED = 'ENABLED',
}

export enum CategoryType {
  ALL = 'ALL',
  EVENT = 'EVENT',
  SYSTEM = 'SYSTEM',
}

export enum PublishStatus {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PRIVATE = 'PRIVATE',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

export enum UserRoleType {
  ADMIN = 'ADMIN',
  ALIAS = 'ALIAS',
  CREATOR = 'CREATOR',
  EDITOR = 'EDITOR',
  USER_RESTRICTED = 'USER_RESTRICTED',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
